import React from "react";
import { Link } from "react-router-dom";

const PageItem = ({ index, link, status }) => {
  return (
    <li className={status}>
      <Link className="page-link" to={link}>
        {index}
      </Link>
    </li>
  );
};

export default PageItem;
