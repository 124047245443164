import React, { useState, useEffect } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/news-icon.png";
import ArticleContent from "./ArticleContent";
import { Link } from "react-router-dom";

const Article = ({ match }) => {
  useEffect(() => {
    fetchBlog();
    fetchItem();
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps
  const [blog, setBlog] = useState({});
  const fetchBlog = async () => {
    const fetchBlog = await fetch(process.env.REACT_APP_BACKEND_URL + "/blog");
    const blog = await fetchBlog.json();
    setBlog(blog);
  };
  const title = blog.title;
  let bg;
  if (blog.banner != null)
    bg = process.env.REACT_APP_BACKEND_URL + blog.banner.url;

  const [item, setItem] = useState({});
  const fetchItem = async () => {
    const fetchItem = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/articles?slug=" + match.params.id
    );
    const item = await fetchItem.json();
    setItem(item);
  };

  const createDate = (datetime) => {
    let date = datetime.slice(0, datetime.indexOf("T"));
    let splitDate = date.split("-");
    let reverseArray = splitDate.reverse();
    var joinDate = reverseArray.join("-");
    return joinDate;
  };
  const article = item[0];
  let date;
  let dateC;
  let categories;
  let images;
  let files;
  let aTitle;
  let content;
  if (article != null) {
    aTitle = article.title;
    content = article.content;
    date = createDate(article.updatedAt + "");
    dateC = createDate(article.createdAt + "");
    categories = article.categories.map((i) => {
      return (
        <Link
          key={i.slug}
          style={{ color: "#112e57", marginRight: "5px" }}
          to={"/blog/category/" + i.slug + "/page/1"}
        >
          {i.title}
        </Link>
      );
    });
    images = article.media.map((i) => {
      if (
        i.mime === "image/jpeg" ||
        i.mime === "image/jpg" ||
        i.mime === "image/png"
      ) {
        return (
          <div key={i.name} className="col-lg-4 col-md-4 col-sm-6">
            <a
              href={process.env.REACT_APP_BACKEND_URL + i.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ margin: "5px", float: "left" }}
                src={process.env.REACT_APP_BACKEND_URL + i.url}
                alt={i.name}
                title={i.name}
              />
            </a>
          </div>
        );
      } else {
        return null;
      }
    });

    files = article.media.map((i) => {
      if (
        i.mime !== "image/jpeg" &&
        i.mime !== "image/jpg" &&
        i.mime !== "image/png"
      ) {
        return (
          <div key={i.name} className="col-lg-12 col-md-12 col-sm-12">
            <a
              style={{ color: "#000000" }}
              href={process.env.REACT_APP_BACKEND_URL + i.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i.name}
            </a>
          </div>
        );
      } else {
        return null;
      }
    });

    let files2 = [];
    for (let i in files) {
      if (files[i] != null) files2.push(files[i]);
    }
    files = files2;
  }
  return (
    <div>
      <ImageTitle img={icon} title={title} bg={bg} />
      <ArticleContent
        title={aTitle}
        content={content}
        date={date}
        dateC={dateC}
        categories={categories}
        images={images}
        files={files}
      />
    </div>
  );
};

export default Article;
