import React from "react";

const DisplayFiles = ({ files }) => {
  if (files != null) {
    if (files.length > 0) {
      return (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="article-files">
            <h1 className="sub-title-6">Συνημμένα</h1>
            <div className="row">{files}</div>
          </div>
        </div>
      );
    }
  }
  return null;
};

export default DisplayFiles;
