import React from "react";
import DisplayFiles from "../default/DisplayFiles";
import ParseContent from "../default/ParseContent";

const ArticleContent = ({
  title,
  content,
  date,
  dateC,
  categories,
  images,
  files,
}) => {
  content = ParseContent(content);
  return (
    <div className="container add-padding">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="article">
            <h1 className="sub-title-6">{title}</h1>
            <h3 className="sub-title-7">
              Δημιουργήθηκε: {dateC} | Ενημερώθηκε: {date}
            </h3>
            <div className="editor" style={{ textAlign: "initial" }}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <br />
            <div className="row">{images}</div>
            <br />
            <hr />
            <div style={{ textAlign: "left" }}>Κατηγορίες: {categories}</div>
          </div>
        </div>
        <DisplayFiles files={files} />
      </div>
    </div>
  );
};

export default ArticleContent;
