import React from "react";

const Welcome = (props) => {
  let image;
  let alt;
  if (props.image != null) {
    image = process.env.REACT_APP_BACKEND_URL + props.image.url;
    alt = props.image.name;
  } else {
    image = "";
    alt = "";
  }
  return (
    <div className="container-fluid add-padding">
      <div className="article">
        <h1 className="sub-title-2">{props.title}</h1>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img className="my-5" src={image} alt={alt} />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="editor" style={{ textAlign: "initial" }}>
              <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
