import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ titles, routes, logo }) => {
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="footer-logo">
            <img
              src={logo}
              alt="Ερέτρια"
              title="Ερέτρια"
              className="aligncenter footer-img"
            />
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <ul className="footer-menu">
                <li>
                  <h2>Δήμος Ερέτριας</h2>
                </li>
                <li>
                  <Link to="#">Αρχαίου Θεάτρου 1,</Link>
                </li>
                <li>
                  <Link to="#">Ερέτρια, Εύβοια, Ελλάδα</Link>
                </li>
                <li>
                  <Link to="#">Τ.Κ. 34008</Link>
                </li>
                <li>
                  <Link to="#">Τηλ.: 22290 62207</Link>
                </li>
                {/* <li>
                  <Link to="#">Fax : 22290 62218</Link>
                </li> */}
                <li>
                  <Link to="#">eretriad@otenet.gr</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <ul className="footer-menu">
                <li>
                  <h2>Πλοήγηση</h2>
                </li>
                <li>
                  <Link to="/" href="#">
                    Αρχική
                  </Link>
                </li>
                <li>
                  <Link to={routes.blog} href="#">
                    {titles.blog}
                  </Link>
                </li>
                <li>
                  <Link to={routes.services} href="#">
                    {titles.services}
                  </Link>
                </li>
                <li>
                  <Link to={routes.business} href="#">
                    {titles.business}
                  </Link>
                </li>
                <li>
                  <Link to={routes.tourism} href="#">
                    {titles.tourism}
                  </Link>
                </li>
                <li>
                  <Link to={routes.citizen} href="#">
                    {titles.citizen}
                  </Link>
                </li>
                <li>
                  <Link to={routes.animal} href="#">
                    {titles.animal}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <ul className="footer-menu d-none">
                <li>
                  <h2>Follow Us</h2>
                </li>
                <li>
                  <Link to={routes.facebookLink}>Facebook</Link>
                </li>
                <li>
                  <Link to={routes.twitterLink}>Twitter</Link>
                </li>
                <li>
                  <Link to={routes.youtubeLink}>Youtube</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <ul className="footer-menu">
                <li>
                  <h2>Σχετικά</h2>
                </li>
                <li>
                  <Link to={routes.termsOfUseLink}>Όροι Χρήσης</Link>
                </li>
                <li>
                  <Link to={routes.termsOfUseLink}>Πολιτική Απορρήτου</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-copyright">
            <p className="copyright aligncenter">
              Δήμος Ερέτριας &copy; Copyright 2020
              <br />
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
