import React from "react";
import ParseContent from "../default/ParseContent";
import DisplayFiles from "../default/DisplayFiles";
import ReactPlayer from "react-player";

const LiveStream = ({ url }) => {
  if (url === "<p>https://eretria.streamings.gr/live/live/index.m3u8</p>")
    return (
      <ReactPlayer
        controls="true"
        className="react-player"
        url="https://eretria.streamings.gr/live/live/index.m3u8"
      />
    );
  return null;
};

const Portrait = ({ portrait }) => {
  if (portrait != null)
    return <img src={portrait} alt={portrait} className="portrait" />;
  else return null;
};

const People = ({ people }) => {
  if (people.length > 0) {
    return <div style={{ width: "100%" }}>{people}</div>;
  }
  return null;
};

const RenderContent = ({ title, content, portrait, images, files }) => {
  if (content != null) {
    const url = content;
    content = ParseContent(content);
    return (
      <>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="article">
            <h1 className="sub-title-6">{title}</h1>
            <div className="editor" style={{ textAlign: "initial" }}>
              <Portrait portrait={portrait} />
              <LiveStream url={url} />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <br />
            <div className="row">{images}</div>
          </div>
        </div>
        <DisplayFiles files={files} />
      </>
    );
  } else {
    return null;
  }
};

const PageContent = ({ title, content, people, files, images, portrait }) => {
  return (
    <div className="container add-padding">
      <div className="row">
        <RenderContent
          title={title}
          content={content}
          files={files}
          images={images}
          portrait={portrait}
        />
        <People people={people} />
      </div>
    </div>
  );
};

export default PageContent;
