import React from "react";
import i404 from "../../assets/img/404.png";
const NotFound = () => {
  return (
    <div>
      <div className="container add-padding" style={{ marginTop: "100px" }}>
        <div className="article">
          <h1 className="display-3">
            Ζήτησες κάτι που δεν υπάρχει στο eretria.gr!
          </h1>
          <br />
          <img style={{ borderRadius: "3%" }} src={i404} alt="404" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
