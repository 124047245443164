import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/all.css";
import Nav from "./components/default/Nav";
import Footer from "./components/default/Footer";
import ScrollToTop from "./components/default/ScrollToTop";
import Home from "./components/Home/Home";
import Blog from "./components/Blog/Blog";
import Page from "./components/Page/Page";
import Service from "./components/Service/Service";
import Business from "./components/Business/Business";
import Tourism from "./components/Tourism/Tourism";
import Citizen from "./components/Citizen/Citizen";
import Animal from "./components/Animal/Animal";
import Article from "./components/Blog/Article";
import Category from "./components/Blog/Category";
import NotFound from "./components/default/NotFound";
import smallLogo from "./assets/img/eretria-small-logo.png";
import newsBtn from "./assets/img/news-btn.jpg";
import newsBtn2 from "./assets/img/news-btn-2.jpg";
import serviceBtn from "./assets/img/services-btn.jpg";
import businessBtn from "./assets/img/business-btn.jpg";
import businessBtn2 from "./assets/img/business-btn-2.jpg";
import tourismBtn from "./assets/img/tourism-btn.jpg";
import citizenBtn from "./assets/img/citizen-btn.jpg";
import animalBtn from "./assets/img/animal-btn.jpg";
import animalBtn2 from "./assets/img/animal-btn-2.jpg";
import footerLogo from "./assets/img/eretria_logo_footer.png";
import bigLogo from "./assets/img/eretria-big-logo.png";
import lightBlue from "./assets/img/lightBlue.jpg";
import darkBlue from "./assets/img/darkBlue.jpg";
import businessIcon from "./assets/img/business-icon.png";
import tourismIcon from "./assets/img/tourism-icon.png";
import animalIcon from "./assets/img/animal-icon.png";
import serviceIcon from "./assets/img/services-icon.png";
import citizenIcon from "./assets/img/citizen-icon.png";
import citizenBtn2 from "./assets/img/citizen-btn-2.jpg";
import blogIcon from "./assets/img/news-icon.png";
import bg from "./assets/img/home-bg.jpg";
import bg1 from "./assets/img/1.jpg";
import bg2 from "./assets/img/2.png";
import bg3 from "./assets/img/3.jpg";
import bg4 from "./assets/img/4.jpg";
import bg5 from "./assets/img/5.jpg";
import bg6 from "./assets/img/6.png";

function App() {
  const images = {
    smallLogo: smallLogo,
    newsBtn: newsBtn,
    serviceBtn: serviceBtn,
    businessBtn: businessBtn,
    businessBtn2: businessBtn2,
    tourismBtn: tourismBtn,
    citizenBtn: citizenBtn,
    animalBtn: animalBtn,
    animalBtn2: animalBtn2,
    footerLogo: footerLogo,
    bigLogo: bigLogo,
    lightBlue: lightBlue,
    darkBlue: darkBlue,
    businessIcon: businessIcon,
    tourismIcon: tourismIcon,
    animalIcon: animalIcon,
    serviceIcon: serviceIcon,
    citizenIcon: citizenIcon,
    blogIcon: blogIcon,
    bg: bg,
    citizenBtn2: citizenBtn2,
    newsBtn2: newsBtn2,
    bg1: bg1,
    bg2: bg2,
    bg3: bg3,
    bg4: bg4,
    bg5: bg5,
    bg6: bg6,
  };

  const routes = {
    home: "/",
    blog: "/blog/page/1",
    services: "/services",
    business: "/business",
    tourism: "/tourism",
    citizen: "/citizen",
    animal: "/animals",
    termsOfUseLink: "/page/politiki-aporritoy-oroi-xrisis",
    privacyPolicyLink: "/page/politiki-aporritoy-oroi-xrisis",
    facebookLink: "#",
    youtubeLink: "#",
    twitterLink: "#",
  };

  const titles = {
    home: "Αρχική",
    blog: "Ενημέρωση",
    services: "Υπηρεσίες",
    business: "Επιχειρήσεις",
    tourism: "Τουρισμός",
    citizen: "Πολίτης",
    animal: "Αδέσποτα",
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Nav routes={routes} titles={titles} images={images} />
        <Switch>
          <Route
            path="/"
            exact
            component={() => (
              <Home titles={titles} routes={routes} images={images} />
            )}
          />
          <Route path="/blog/page/:id" component={Blog} />
          <Route path="/blog/article/:id" component={Article} />
          <Route path="/blog/category/:title/page/:id" component={Category} />
          <Route path="/page/:id" component={Page} />
          <Route path="/services" exact component={Service} />
          <Route path="/business" exact component={Business} />
          <Route path="/tourism" exact component={Tourism} />
          <Route path="/citizen" exact component={Citizen} />
          <Route path="/animals" exact component={Animal} />
          <Route component={NotFound} />
        </Switch>
        <Footer routes={routes} titles={titles} logo={footerLogo} />
        <CookieConsent buttonText="ΑΠΟΔΕΧΟΜΑΙ" cookieName="TermsOfUseAccept">
          Χρησιμοποιούμε τεχνολογίες, όπως cookies, για τη σωστή λειτουργία της
          ιστοσελίδας. Κάντε κλικ για να συμφωνήσετε με τη χρήση αυτής της
          τεχνολογίας.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
