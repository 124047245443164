import React from "react";
import { Link } from "react-router-dom";
import bg_static from "../../assets/img/bg-big-menu.jpg";

const BigMenu = ({ titles, routes, images, bg }) => {
  return (
    <div className="container-fluid">
      <div
        id="bigMenu"
        className="row big-menu-background"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div
          className="col-lg-8 col-sm-8 p-0"
          style={{
            borderRight: "1px solid #495057",
          }}
        >
          <Link
            to={routes.home}
            className="col-lg-4 col-md-4 col-sm-4 col-lg-offset-5 col-md-offset-4 col-sm-offset-4"
          >
            <img
              src={images.bigLogo}
              alt="eretria-big-logo"
              className="main-logo"
            />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-4 p-0 d-none d-sm-block">
          <div className="row p-0 m-0">
            <div className="col-sm-6 p-0">
              <Link to={routes.blog}>
                <div className="big-menu-item-inner-text">
                  <img
                    src={images.blogIcon}
                    className="big-menu-item-icon"
                    alt={titles.blog}
                    title={titles.blog}
                  />
                  <span className="big-menu-item-text">{titles.blog}</span>
                </div>
                <img
                  src={images.bg4}
                  className="big-menu-item-bg"
                  alt={titles.blog}
                  title={titles.blog}
                />
              </Link>
            </div>
            <div className="col-sm-6 p-0">
              <Link to={routes.services}>
                <div className="big-menu-item-inner-text">
                  <img
                    src={images.serviceIcon}
                    className="big-menu-item-icon"
                    alt={titles.services}
                    title={titles.services}
                  />
                  <span className="big-menu-item-text">{titles.services}</span>
                </div>
                <img
                  src={images.bg3}
                  className="big-menu-item-bg"
                  alt={titles.services}
                  title={titles.services}
                />
              </Link>
            </div>
          </div>
          <div className="row p-0 m-0">
            <div className="col-sm-6 p-0">
              <Link to={routes.business}>
                <div className="big-menu-item-inner-text">
                  <img
                    src={images.businessIcon}
                    className="big-menu-item-icon"
                    alt={titles.business}
                    title={titles.business}
                  />
                  <span className="big-menu-item-text">{titles.business}</span>
                </div>
                <img
                  src={images.bg6}
                  className="big-menu-item-bg"
                  alt={titles.business}
                  title={titles.business}
                />
              </Link>
            </div>
            <div className="col-sm-6 p-0">
              <Link to={routes.tourism}>
                <div className="big-menu-item-inner-text">
                  <img
                    src={images.tourismIcon}
                    className="big-menu-item-icon"
                    alt={titles.tourism}
                    title={titles.tourism}
                  />
                  <span className="big-menu-item-text">{titles.tourism}</span>
                </div>
                <img
                  src={images.bg5}
                  className="big-menu-item-bg"
                  alt={titles.tourism}
                  title={titles.tourism}
                />
              </Link>
            </div>
          </div>
          <div className="row p-0 m-0">
            <div className="col-sm-6 p-0">
              <Link to={routes.citizen}>
                <div className="big-menu-item-inner-text">
                  <img
                    src={images.citizenIcon}
                    className="big-menu-item-icon"
                    alt={titles.citizen}
                    title={titles.citizen}
                  />
                  <span className="big-menu-item-text">{titles.citizen}</span>
                </div>
                <img
                  src={images.bg2}
                  className="big-menu-item-bg"
                  alt={titles.citizen}
                  title={titles.citizen}
                />
              </Link>
            </div>
            <div className="col-sm-6 p-0">
              <Link to={routes.animal}>
                <div className="big-menu-item-inner-text">
                  <img
                    src={images.animalIcon}
                    className="big-menu-item-icon"
                    alt={titles.animal}
                    title={titles.animal}
                  />
                  <span className="big-menu-item-text">{titles.animal}</span>
                </div>
                <img
                  src={images.bg1}
                  className="big-menu-item-bg"
                  alt={titles.animal}
                  title={titles.animal}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigMenu;
