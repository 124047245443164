import React from "react";

const RenderEmpty = (props) => {
  if (props.titles.length <= 0 && props.title === "" && props.content === "") {
    return (
      <div className="container">
        <h1 className="sub-title-4">Δεν υπάρχει περιεχόμενο για τη σελίδα</h1>
      </div>
    );
  } else return null;
};

export default RenderEmpty;
