import React, { useEffect, useState } from "react";
import Card from "./HomeNewsCard";
import MoreBtn from "../default/MoreBtn";
import bg_default from "../../assets/img/default.jpg";

const HomeNews = () => {
  useEffect(() => {
    fetchItem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [item, setItem] = useState({});
  const fetchItem = async () => {
    const fetchItem = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        "/articles?_start=0&_limit=4&_sort=updatedAt%3ADESC&categories=6230862d2c720b0df0a4e0f8"
    );
    const item = await fetchItem.json();
    setItem(item);
  };
  let title = "Τελευταία Νέα";
  let link = "/blog/page/1";
  let titles = [];
  let links = [];
  let images = [];
  let dates = [];
  let homeNews = [];
  const createDate = (datetime) => {
    let date = datetime.slice(0, datetime.indexOf("T"));
    let splitDate = date.split("-");
    let reverseArray = splitDate.reverse();
    var joinDate = reverseArray.join("-");
    return joinDate;
  };
  if (item != null) {
    for (let i in item) {
      titles.push(item[i].title);
      links.push("/blog/article/" + item[i].slug);
      if (item[i].thumbnail != null) {
        if (
          item[i].thumbnail.name.slice(0, 6) === "small_" ||
          item[i].thumbnail.name.slice(0, 6) === "large_" ||
          item[i].thumbnail.name.slice(0, 7) === "medium_" ||
          item[i].thumbnail.name.slice(0, 10) === "thumbnail_"
        ) {
          images.push(
            process.env.REACT_APP_BACKEND_URL + item[i].thumbnail.url
          );
        } else {
          images.push(
            process.env.REACT_APP_BACKEND_URL +
              item[i].thumbnail.formats.small.url
          );
        }
      } else images.push(bg_default);
      if (item[i].updatedAt != null) dates.push(createDate(item[i].updatedAt));
    }
    for (let i = 0; i < titles.length; i++) {
      homeNews.push(
        <Card
          key={i}
          title={titles[i]}
          img={images[i]}
          link={links[i]}
          date={dates[i]}
        />
      );
    }
  }

  return (
    <div className="container-fluid add-padding">
      <div className="article">
        <h1 className="sub-title-2">{title}</h1>
        <div className="row">{homeNews}</div>
        <MoreBtn link={link} />
      </div>
    </div>
  );
};

export default HomeNews;
