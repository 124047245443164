import React from "react";
import ParseContent from "../default/ParseContent";

const Description = ({ title, content }) => {
  if (title !== "") {
    content = ParseContent(content);
    return (
      <div>
        <div className="container add-padding">
          <div className="article">
            <h1 className="sub-title-2">{title}</h1>
            <div className="editor" style={{ textAlign: "initial" }}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Description;
