import React, { useState, useEffect } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/news-icon.png";
import BlogItem from "./BlogItem";
import PageItem from "./PageItem";
import Next from "./Next";
import Previous from "./Previous";
import SubMenu from "../default/SubMenu";
import bg_default from "../../assets/img/default.jpg";

const RenderEmpty = ({ blogItems, tit }) => {
  if (blogItems.length <= 0 && tit.length <= 0) {
    return (
      <div className="container">
        <h1 className="sub-title-4">Δεν υπάρχει περιεχόμενο για τη σελίδα</h1>
      </div>
    );
  } else return null;
};

const RenderArticles = ({ blogItems, previous, pageItems, next }) => {
  if (blogItems.length > 0) {
    return (
      <>
        <div className="container add-padding">
          <div className="article">
            {/* <h1 className="sub-title-2">Τελευταία Νέα</h1> */}
            <div className="row">{blogItems}</div>
            <ul className="pagination justify-content-center py-5">
              {previous}
              {pageItems}
              {next}
            </ul>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

const Blog = ({ match }) => {
  useEffect(() => {
    fetchBlog();
    fetchCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchItem();
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps
  const [count, setCount] = useState({});
  const fetchCount = async () => {
    const fetchCount = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/articles/count"
    );
    const count = await fetchCount.json();
    setCount(count);
  };
  const [blog, setBlog] = useState({});
  const fetchBlog = async () => {
    const fetchBlog = await fetch(process.env.REACT_APP_BACKEND_URL + "/blog");
    const blog = await fetchBlog.json();
    setBlog(blog);
  };

  let tit = [];
  let lin = [];
  for (let i in blog.categories) {
    tit.push(blog.categories[i].title);
    lin.push("/blog/category/" + blog.categories[i].slug + "/page/1");
  }

  for (let i in blog.pages) {
    tit.push(blog.pages[i].title);
    lin.push("/page/" + blog.pages[i].slug);
  }

  const perPage = 9;
  let pages = Math.ceil(count / perPage);
  if (pages === 0) pages = 1;

  const pageItems = [];
  let index = 0;
  const currentPage = parseInt(match.params.id);
  if (currentPage < 10) index = 0;
  else index = currentPage - 10;

  let offset = 10;
  if (currentPage + 10 > pages) {
    offset = pages - currentPage;
  } else if (currentPage + 10 > pages) {
    offset = pages - currentPage;
  }

  for (let i = index; i < currentPage + offset; i++) {
    if (currentPage === i + 1)
      pageItems.push(
        <PageItem
          key={i}
          index={i + 1}
          link={"/blog/page/" + (i + 1)}
          status={"page-item active"}
        />
      );
    else
      pageItems.push(
        <PageItem
          key={i}
          index={i + 1}
          link={"/blog/page/" + (i + 1)}
          status={"page-item"}
        />
      );
  }
  let next;
  let previous;
  if (currentPage === 1 && pages > 1) {
    next = (
      <Next link={"/blog/page/" + (currentPage + 1)} status={"page-item"} />
    );
    previous = <Previous link={"#"} status={"page-item disabled"} />;
  } else if (currentPage === pages && pages > 1) {
    next = <Next link={"#"} status={"page-item disabled"} />;
    previous = (
      <Previous link={"/blog/page/" + (currentPage - 1)} status={"page-item"} />
    );
  } else if (pages === 1) {
    next = <Next link={"#"} status={"page-item disabled"} />;
    previous = <Previous link={"#"} status={"page-item disabled"} />;
  } else {
    next = (
      <Next link={"/blog/page/" + (currentPage + 1)} status={"page-item"} />
    );
    previous = (
      <Previous link={"/blog/page/" + (currentPage - 1)} status={"page-item"} />
    );
  }

  const [item, setItem] = useState({});
  const fetchItem = async () => {
    const fetchItem = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        "/articles?_start=" +
        (match.params.id - 1) * perPage +
        "&_limit=" +
        perPage +
        "&_sort=updatedAt%3ADESC"
    );
    const item = await fetchItem.json();
    setItem(item);
  };
  let title;
  if (blog.title === null || blog.title === "" || blog.title === undefined)
    title = "Ενημέρωση";
  else title = blog.title;

  let titles = [];
  let links = [];
  let images = [];
  let dates = [];
  let blogItems = [];
  let bg;
  const createDate = (datetime) => {
    let date = datetime.slice(0, datetime.indexOf("T"));
    let splitDate = date.split("-");
    let reverseArray = splitDate.reverse();
    var joinDate = reverseArray.join("-");
    return joinDate;
  };
  if (item != null) {
    for (let i in item) {
      titles.push(item[i].title);
      links.push("/blog/article/" + item[i].slug);
      if (item[i].thumbnail != null) {
        if (
          item[i].thumbnail.name.slice(0, 6) === "small_" ||
          item[i].thumbnail.name.slice(0, 6) === "large_" ||
          item[i].thumbnail.name.slice(0, 7) === "medium_" ||
          item[i].thumbnail.name.slice(0, 10) === "thumbnail_"
        ) {
          images.push(
            process.env.REACT_APP_BACKEND_URL + item[i].thumbnail.url
          );
        } else if (item[i].thumbnail.formats.small != null) {
          images.push(
            process.env.REACT_APP_BACKEND_URL +
              item[i].thumbnail.formats.small.url
          );
        } else {
          images.push(bg_default);
        }
      } else images.push(bg_default);

      if (item[i].updatedAt != null) dates.push(createDate(item[i].updatedAt));
    }
    for (let i = 0; i < titles.length; i++) {
      blogItems.push(
        <BlogItem
          key={i}
          title={titles[i]}
          img={images[i]}
          link={links[i]}
          date={dates[i]}
        />
      );
    }
  }

  if (blog.banner != null)
    bg = process.env.REACT_APP_BACKEND_URL + blog.banner.url;

  return (
    <div>
      <ImageTitle img={icon} title={title} bg={bg} />
      <SubMenu links={lin} titles={tit} icon={icon} />
      <RenderArticles
        blogItems={blogItems}
        previous={previous}
        pageItems={pageItems}
        next={next}
      />
      <RenderEmpty blogItems={blogItems} tit={tit} />
    </div>
  );
};

export default Blog;
