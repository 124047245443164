import React from "react";
import SubItem from "./SubItem";

const RenderSubmenu = ({ titles, subItems }) => {
  if (titles.length > 0) {
    return (
      <div className="container add-padding">
        <div className="article">
          {/* <h1 className="sub-title-2">Περιεχόμενα</h1> */}
          <div className="row">{subItems}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SubMenu = ({ links, titles, icon }) => {
  const subItems = [];
  for (let i = 0; i < titles.length; i++) {
    subItems.push(
      <SubItem key={i} link={links[i]} title={titles[i]} icon={icon} />
    );
  }

  return <RenderSubmenu titles={titles} subItems={subItems} />;
};

export default SubMenu;
