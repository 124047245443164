import React from "react";
import { Link } from "react-router-dom";

const HorizontalCard = ({ link, img, title, attribute }) => {
  if (img === undefined) img = null;
  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <Link to={link}>
        <div className="card mb-3">
          <div className="row no-gutters">
            <div className="col-md-2">
              <img className="profile-page" src={img} alt={title} />
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <h5 className="card-title-2">{title}</h5>
                <p className="card-text">
                  <small className="text-muted">{attribute}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HorizontalCard;
