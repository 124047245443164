import React from "react";
import { Link } from "react-router-dom";

const HomeNewsCard = ({ link, img, title, date }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-8 ml-auto mr-auto">
      <Link to={link}>
        <div className="card">
          <img className="card-img-top" src={img} alt={title} />
          <span className="card-date">{date}</span>
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">
              Περισσότερα
              <i
                className="fas fa-angle-right"
                style={{ float: "right", lineHeight: "inherit" }}
              ></i>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HomeNewsCard;
