import React from "react";
import MoreBtn from "../default/MoreBtn";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import logo from "../../assets/img/eretria-big-logo.png";

const HomeEvents = () => {
  const title = "ΕΚΔΗΛΩΣΕΙΣ";
  const link = "/blog/category/ekdiloseis/page/1";

  return (
    <div className="container-fluid add-padding">
      <div className="article">
        <h1 className="sub-title-2">{title}</h1>
        <br></br>
        <div className="calendar">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <Calendar locale="el-GR" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div style={{ backgroundColor: "#112e57", height: "100%" }}>
                <img
                  src={logo}
                  className="calendar-logo"
                  alt="Δήμος Ερέτριας"
                />
              </div>
            </div>
          </div>
        </div>
        <MoreBtn link={link} />
      </div>
    </div>
  );
};

export default HomeEvents;
