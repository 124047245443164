import React from "react";
import { Link } from "react-router-dom";

const Previous = ({ link, status }) => {
  return (
    <li className={status}>
      <Link className="page-link" to={link} aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span className="sr-only">Previous</span>
      </Link>
    </li>
  );
};

export default Previous;
