import React, { useState, useEffect } from "react";
import PageContent from "./PageContent";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/news-icon.png";
import SubMenu from "../default/SubMenu";
import HorizontalCard from "./HorizontalCard";

const Page = ({ match }) => {
  useEffect(() => {
    fetchPage();
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps
  const [page, setPage] = useState({});
  const fetchPage = async () => {
    const fetchPage = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/pages?slug=" + match.params.id
    );
    const page = await fetchPage.json();
    setPage(page);
  };

  let p = page[0];
  let title;
  let content;
  let media;
  let images;
  let files;
  let portrait;
  let bg;
  let titles = [];
  let links = [];
  let showContent;
  let people = [];
  if (p != null) {
    if (p.banner != null) bg = process.env.REACT_APP_BACKEND_URL + p.banner.url;
    showContent = p.showContent;
    if (showContent) content = p.content;
    else content = null;
    media = p.media;
    title = p.title;
    if (p.profilePicture != null)
      portrait = process.env.REACT_APP_BACKEND_URL + p.profilePicture.url;
    images = media.map((i) => {
      if (
        i.mime === "image/jpeg" ||
        i.mime === "image/jpg" ||
        i.mime === "image/png"
      ) {
        return (
          <div key={i.name} className="col-lg-4 col-md-4 col-sm-12">
            <a
              href={process.env.REACT_APP_BACKEND_URL + i.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ margin: "5px", float: "left" }}
                src={process.env.REACT_APP_BACKEND_URL + i.url}
                alt={i.name}
                title={i.name}
              />
            </a>
          </div>
        );
      } else {
        return null;
      }
    });

    files = media.map((i) => {
      if (
        i.mime !== "image/jpeg" &&
        i.mime !== "image/jpg" &&
        i.mime !== "image/png"
      ) {
        return (
          <div key={i.name} className="col-lg-12 col-md-12 col-sm-12">
            <a
              style={{ color: "#000000" }}
              href={process.env.REACT_APP_BACKEND_URL + i.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i.name}
            </a>
          </div>
        );
      } else {
        return null;
      }
    });

    let files2 = [];
    for (let i in files) {
      if (files[i] != null) files2.push(files.slice(i, i + 1));
    }
    files = files2;

    if (p.categories != null) {
      for (let i in p.categories) {
        titles.push(p.categories[i].title);
        links.push("/blog/category/" + p.categories[i].slug + "/page/1");
      }
    }
    if (p.pages != null) {
      for (let i in p.pages) {
        titles.push(p.pages[i].title);
        links.push("/page/" + p.pages[i].slug);
      }
    }

    let pTitle = [];
    let pLink = [];
    let pImg = [];
    let pAttribute = [];
    if (p.people != null) {
      for (let i in p.people) {
        if (p.people[i].title != null) pTitle.push(p.people[i].title);
        else pTitle.push(null);

        if (p.people[i].slug != null) pLink.push("/page/" + p.people[i].slug);
        else pLink.push(null);

        if (p.people[i].profilePicture != null)
          pImg.push(
            process.env.REACT_APP_BACKEND_URL + p.people[i].profilePicture.url
          );
        else pImg.push(null);

        if (p.people[i].attribute != null)
          pAttribute.push(p.people[i].attribute);
        else pAttribute.push(null);
      }
      for (let i in p.people) {
        people.push(
          <HorizontalCard
            key={i}
            title={pTitle[i]}
            link={pLink[i]}
            img={pImg[i]}
            attribute={pAttribute[i]}
          />
        );
      }
    }
  }

  return (
    <div>
      <ImageTitle img={null} title={null} bg={bg} />
      <SubMenu links={links} titles={titles} icon={icon} />
      <PageContent
        title={title}
        content={content}
        people={people}
        files={files}
        images={images}
        portrait={portrait}
      />
    </div>
  );
};

export default Page;
