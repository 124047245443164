import React from "react";
import { Link } from "react-router-dom";

const MoreBtn = ({ link }) => {
  return (
    <Link to={link} className="btn btn-dark more-btn">
      ΠΕΡΙΣΣΟΤΕΡΑ
    </Link>
  );
};

export default MoreBtn;
