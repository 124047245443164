import React, { useEffect, useState } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/animal-icon.png";
import SubMenu from "../default/SubMenu";
import Description from "../default/Description";
import RenderEmpty from "../default/RenderEmpty";

const Animal = () => {
  useEffect(() => {
    fetchAnimal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [animal, setAnimal] = useState({});
  const fetchAnimal = async () => {
    const fetchAnimal = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/animal"
    );
    const animal = await fetchAnimal.json();
    setAnimal(animal);
  };

  let titles = [];
  let links = [];
  if (animal.categories != null) {
    for (let i in animal.categories) {
      titles.push(animal.categories[i].title);
      links.push("/blog/category/" + animal.categories[i].slug + "/page/1");
    }
  }
  if (animal.pages != null) {
    for (let i in animal.pages) {
      titles.push(animal.pages[i].title);
      links.push("/page/" + animal.pages[i].slug);
    }
  }

  let title;
  if (
    animal.title === null ||
    animal.title === "" ||
    animal.title === undefined
  )
    title = "Αδέσποτα";
  else title = animal.title;

  let banner = "";
  if (animal.banner != null)
    banner = process.env.REACT_APP_BACKEND_URL + animal.banner.url;

  let contentTitle = "";
  if (animal.contentTitle != null) contentTitle = animal.contentTitle;

  let content = "";
  if (animal.content != null) content = animal.content;

  return (
    <div>
      <ImageTitle img={icon} title={title} bg={banner} />
      <SubMenu links={links} titles={titles} icon={icon} />
      <Description title={contentTitle} content={content} />
      <RenderEmpty titles={titles} title={contentTitle} content={content} />
    </div>
  );
};

export default Animal;
