import React, { useEffect, useState } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/services-icon.png";
import ServiceMunicipalityItem from "./ServiceMunicipalityItem";
import Management from "./Management";

const Service = () => {
  useEffect(() => {
    fetchService();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [service, setService] = useState({});
  const fetchService = async () => {
    const fetchService = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/service"
    );
    const service = await fetchService.json();
    setService(service);
  };

  let title;
  if (
    service.title === null ||
    service.title === "" ||
    service.title === undefined
  )
    title = "Υπηρεσίες";
  else title = service.title;

  let banner;
  let managements = [];
  if (service != null) {
    if (service.banner != null)
      banner = process.env.REACT_APP_BACKEND_URL + service.banner.url;
    if (service.managements != null) {
      for (let i in service.managements) {
        managements.push(service.managements[i]);
      }
    }
  }
  let managementItems = [];
  for (let i in managements) {
    managementItems.push(<Management key={i} slug={managements[i].slug} />);
  }

  let pages = [];
  if (service.pages != null) {
    for (let i in service.pages) {
      pages.push(
        <ServiceMunicipalityItem
          title={service.pages[i].title}
          link={"/page/" + service.pages[i].slug}
          icon={icon}
        />
      );
    }
  }

  if (service.categories != null) {
    for (let i in service.categories) {
      pages.push(
        <ServiceMunicipalityItem
          title={service.categories[i].title}
          link={"/blog/category/" + service.categories[i].slug + "/page/1"}
          icon={icon}
        />
      );
    }
  }

  return (
    <div>
      <ImageTitle img={icon} title={title} bg={banner} />
      <div className="container add-padding">
        <div className="article">
          <div className="row">{pages}</div>
          <div className="row">{managementItems}</div>
        </div>
      </div>
    </div>
  );
};

export default Service;
