import React from "react";
import { Link } from "react-router-dom";
const SubItem = ({ link, icon, title }) => {
  return (
    <div className="col-lg-4 col-md-12 col-sm-12">
      <div className="citizen-card">
        <Link to={link}>
          <div className="citizen-title">
            <div
              style={{
                width: "20%",
                float: "left",
              }}
            >
              <img className="citizen-img" src={icon} alt={title} />
            </div>
            <div
              style={{
                width: "80%",
                float: "right",
              }}
            >
              <p>{title}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SubItem;
