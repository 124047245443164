import React from "react";
import { Link } from "react-router-dom";

const Next = ({ link, status }) => {
  return (
    <li className={status}>
      <Link className="page-link" to={link} aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        <span className="sr-only">Next</span>
      </Link>
    </li>
  );
};

export default Next;
