import React, { useEffect, useState } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/business-icon.png";
import BusinessSubMenu from "./BusinessSubmenu";
import Description from "../default/Description";

const RenderEmpty = ({
  businessTitles,
  healthTitles,
  contentTitle,
  content,
}) => {
  if (
    businessTitles.length <= 0 &&
    healthTitles.length <= 0 &&
    contentTitle === "" &&
    content === ""
  ) {
    return (
      <div className="container">
        <h1 className="sub-title-4">Δεν υπάρχει περιεχόμενο για τη σελίδα</h1>
      </div>
    );
  } else return null;
};

const Business = () => {
  useEffect(() => {
    fetchBusiness();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [business, setBusiness] = useState({});
  const fetchBusiness = async () => {
    const fetchBusiness = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/business"
    );
    const business = await fetchBusiness.json();
    setBusiness(business);
  };

  let businessTitles = [];
  let businessLinks = [];
  if (business.businessCategories != null) {
    for (let i in business.businessCategories) {
      businessTitles.push(business.businessCategories[i].title);
      businessLinks.push(
        "/blog/category/" + business.businessCategories[i].slug + "/page/1"
      );
    }
  }
  if (business.businessPages != null) {
    for (let i in business.businessPages) {
      businessTitles.push(business.businessPages[i].title);
      businessLinks.push("/page/" + business.businessPages[i].slug);
    }
  }

  let healthTitles = [];
  let healthLinks = [];
  if (business.healthCategories != null) {
    for (let i in business.healthCategories) {
      healthTitles.push(business.healthCategories[i].title);
      healthLinks.push(
        "/blog/category/" + business.healthCategories[i].slug + "/page/1"
      );
    }
  }
  if (business.healthPages != null) {
    for (let i in business.healthPages) {
      healthTitles.push(business.healthPages[i].title);
      healthLinks.push("/page/" + business.healthPages[i].slug);
    }
  }

  let title;
  if (
    business.title === null ||
    business.title === "" ||
    business.title === undefined
  )
    title = "Επιχειρήσεις";
  else title = business.title;

  let banner;
  if (business.banner != null)
    banner = process.env.REACT_APP_BACKEND_URL + business.banner.url;

  let contentTitle = "";
  if (business.contentTitle != null) contentTitle = business.contentTitle;

  let content = "";
  if (business.content != null) content = business.content;

  return (
    <div>
      <ImageTitle img={icon} title={title} bg={banner} />
      <div style={{ marginBottom: "50px" }}>
        <BusinessSubMenu
          links={businessLinks}
          titles={businessTitles}
          icon={icon}
          header={"Τοπική επιχειρηματικότητα"}
        />
        <BusinessSubMenu
          links={healthLinks}
          titles={healthTitles}
          icon={icon}
          header={"Φορείς Υγείας"}
        />
      </div>
      <Description title={contentTitle} content={content} />
      <RenderEmpty
        businessTitles={businessTitles}
        healthTitles={healthTitles}
        content={content}
        contentTitle={contentTitle}
      />
    </div>
  );
};

export default Business;
