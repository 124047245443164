import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Department = ({ slug, icon }) => {
  useEffect(() => {
    fetchDepartment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [department, setDepartment] = useState({});
  const fetchDepartment = async () => {
    const fetchDepartment = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/departments?slug=" + slug
    );
    const department = await fetchDepartment.json();
    setDepartment(department);
  };
  let title;
  let link = "";
  if (department[0] != null) {
    title = department[0].title;
    if (department[0].page != null) link = "/page/" + department[0].page.slug;
  }
  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <Link to={link}>
        <div className="dmt-card" style={{ backgroundImage: `url(${icon})` }}>
          <div className="dmt-title">
            <p>{title}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Department;
