import React from "react";
import bg_default from "../../assets/img/eretria_logo_blank.png";
const ImageTitle = ({ title, img, bg }) => {
  let imgClass;
  if (bg == null && img == null) {
    img = bg_default;
    imgClass = "page-header-image";
  }
  return (
    <div
      className="container-fluid overlay page-header"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container">
        <div className="page-header-placement">
          <img className={imgClass} src={img} alt={title} />
          <h1 className="page-header-title">{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default ImageTitle;
