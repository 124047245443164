import React from "react";
import { Link } from "react-router-dom";

const Nav = ({ routes, titles, images }) => {
  return (
    <div>
      <nav
        id="navbar"
        className="navbar navbar-expand-sm fixed-top navbar-dark bg-blue p-0"
      >
        <a href="/" className="navbar-brand">
          <img
            className="small-logo"
            src={images.smallLogo}
            alt={titles.home}
            title={titles.home}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse ml-auto"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto">
            <div className="hide-main-menu">
              <li className="nav-item p-0">
                <Link to={routes.blog} className="nav-link p-0">
                  <img
                    className="small-menu-icon"
                    src={images.newsBtn}
                    alt={titles.blog}
                    title={titles.blog}
                  />
                </Link>
              </li>
              <li className="nav-item p-0">
                <Link to={routes.services} className="nav-link p-0">
                  <img
                    className="small-menu-icon"
                    src={images.serviceBtn}
                    alt={titles.services}
                    title={titles.services}
                  />
                </Link>
              </li>
              <li className="nav-item p-0">
                <Link to={routes.business} className="nav-link p-0">
                  <img
                    className="small-menu-icon"
                    src={images.businessBtn}
                    alt={titles.business}
                    title={titles.business}
                  />
                </Link>
              </li>
              <li className="nav-item p-0">
                <Link to={routes.tourism} className="nav-link p-0">
                  <img
                    className="small-menu-icon"
                    src={images.tourismBtn}
                    alt={titles.tourism}
                    title={titles.tourism}
                  />
                </Link>
              </li>
              <li className="nav-item p-0">
                <Link to={routes.citizen} className="nav-link p-0">
                  <img
                    className="small-menu-icon"
                    src={images.citizenBtn}
                    alt={titles.citizen}
                    title={titles.citizen}
                  />
                </Link>
              </li>
              <li className="nav-item p-0">
                <Link to={routes.animal} className="nav-link p-0">
                  <img
                    className="small-menu-icon"
                    src={images.animalBtn}
                    alt={titles.animal}
                    title={titles.animal}
                  />
                </Link>
              </li>
            </div>
            <div className="hide-burger-menu">
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <li className="nav-item navbar-item-news p-0">
                      <Link
                        to={routes.blog}
                        className="nav-link p-0"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <img
                          className="small-menu-icon"
                          src={images.newsBtn}
                          alt={titles.blog}
                          title={titles.blog}
                        />
                        <div className="small-menu-item-text">
                          {titles.blog}
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item navbar-item-services p-0">
                      <Link
                        to={routes.services}
                        className="nav-link p-0"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <img
                          className="small-menu-icon"
                          src={images.serviceBtn}
                          alt={titles.services}
                          title={titles.services}
                        />
                        <div className="small-menu-item-text">
                          {titles.services}
                        </div>
                      </Link>
                    </li>
                  </div>
                  <div className="row">
                    <li className="nav-item navbar-item-business p-0">
                      <Link
                        to={routes.business}
                        className="nav-link p-0"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <img
                          className="small-menu-icon"
                          src={images.businessBtn}
                          alt={titles.business}
                          title={titles.business}
                        />
                        <div className="small-menu-item-text">
                          {titles.business}
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item navbar-item-citizen p-0">
                      <Link
                        to={routes.citizen}
                        className="nav-link p-0"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <img
                          className="small-menu-icon"
                          src={images.citizenBtn}
                          alt={titles.citizen}
                          title={titles.citizen}
                        />
                        <div className="small-menu-item-text">
                          {titles.citizen}
                        </div>
                      </Link>
                    </li>
                  </div>
                  <div className="row">
                    <li className="nav-item navbar-item-animals p-0">
                      <Link
                        to={routes.animal}
                        className="nav-link p-0"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <img
                          className="small-menu-icon"
                          src={images.animalBtn}
                          alt={titles.animal}
                          title={titles.animal}
                        />
                        <div className="small-menu-item-text">
                          {titles.animal}
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item navbar-item-tourism p-0">
                      <Link
                        to={routes.tourism}
                        className="nav-link p-0"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <img
                          className="small-menu-icon"
                          src={images.tourismBtn}
                          alt={titles.tourism}
                          title={titles.tourism}
                        />
                        <div className="small-menu-item-text">
                          {titles.tourism}
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
