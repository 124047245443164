import React from "react";
import { useEffect, useState } from "react";
import SliderItem from "./SliderItem";
import { Splide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const UsefulLinks = () => {
  useEffect(() => {
    fetchUsefulLinks();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [usefulLinks, setUsefulLinks] = useState({});
  const fetchUsefulLinks = async () => {
    const fetchUsefulLinks = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/links"
    );
    const usefulLinks = await fetchUsefulLinks.json();
    setUsefulLinks(usefulLinks);
  };
  let title = "Χρήσιμοι Σύνδεσμοι";
  let style1 = "links-card";
  let style2 = "card-img-top";
  let titles = [];
  let links = [];
  let images = [];
  let sliderItems = [];
  let usefulLink = [];
  if (usefulLinks != null) {
    for (let j in usefulLinks) {
      usefulLink.push(usefulLinks[j]);
    }
    for (let i = 0; i < usefulLink.length; i++) {
      titles.push(usefulLink[i].title);
      links.push(usefulLink[i].link);
      if (usefulLink[i].thumbnail != null)
        images.push(
          process.env.REACT_APP_BACKEND_URL +
            usefulLink[i].thumbnail.formats.small.url
        );
    }
    for (let i = 0; i < titles.length; i++) {
      sliderItems.push(
        <SliderItem
          key={i + i}
          title={titles[i]}
          img={images[i]}
          link={links[i]}
          style1={style1}
          style2={style2}
        />
      );
    }
  }

  return (
    <div className="container-fluid add-padding">
      <div className="article">
        <h1 className="sub-title-2">{title}</h1>
        <Splide
          options={{
            perPage: 4,
            pagination: false,
            pauseOnHover: true,
            breakpoints: {
              576: {
                perPage: 1,
              },
              760: {
                perPage: 2,
              },
              900: {
                perPage: 2,
              },
            },
            rewind: true,
          }}
        >
          {sliderItems}
        </Splide>
      </div>
    </div>
  );
};

export default UsefulLinks;
