import React, { useEffect, useState } from "react";
import SliderItem from "./SliderItem";
import { Splide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const Slider = () => {
  useEffect(() => {
    fetchItem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [item, setItem] = useState({});
  const fetchItem = async () => {
    const fetchItem = await fetch(process.env.REACT_APP_BACKEND_URL + "/home");
    const item = await fetchItem.json();
    setItem(item);
  };
  let titles = [];
  let links = [];
  let images = [];
  let sliderItems = [];
  let style1 = [];
  let style2 = [];
  if (item.gallery != null) {
    for (let i = 0; i < item.gallery.length; i++) {
      titles.push(item.gallery[i].name);
      links.push("#");
      images.push(process.env.REACT_APP_BACKEND_URL + item.gallery[i].url);
    }
    for (let i = 0; i < titles.length; i++) {
      sliderItems.push(
        <SliderItem
          key={i + i}
          title={titles[i]}
          img={images[i]}
          link={links[i]}
          style1={style1}
          style2={style2}
        />
      );
    }
  }

  return (
    <div className="container-fluid add-padding">
      <div className="article">
        <h1 className="sub-title-5"> Ο Δήμος μας</h1>{" "}
        <Splide
          options={{
            cover: true,
            rewind: true,
            heightRatio: 0.5,
            pauseOnHover: true,
          }}
        >
          {sliderItems}
        </Splide>
      </div>
    </div>
  );
};

export default Slider;
