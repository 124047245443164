import React, { useEffect, useState } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/citizen-icon.png";
import SubMenu from "../default/SubMenu";
import Description from "../default/Description";
import RenderEmpty from "../default/RenderEmpty";

const Citizen = () => {
  useEffect(() => {
    fetchCitizen();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [citizen, setCitizen] = useState({});
  const fetchCitizen = async () => {
    const fetchCitizen = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/citizen"
    );
    const citizen = await fetchCitizen.json();
    setCitizen(citizen);
  };

  let titles = [];
  let links = [];
  if (citizen.categories != null) {
    for (let i in citizen.categories) {
      titles.push(citizen.categories[i].title);
      links.push("/blog/category/" + citizen.categories[i].slug + "/page/1");
    }
  }
  if (citizen.pages != null) {
    for (let i in citizen.pages) {
      titles.push(citizen.pages[i].title);
      links.push("/page/" + citizen.pages[i].slug);
    }
  }

  let title;
  if (
    citizen.title === null ||
    citizen.title === "" ||
    citizen.title === undefined
  )
    title = "Πολίτης";
  else title = citizen.title;

  let banner;
  if (citizen.banner != null)
    banner = process.env.REACT_APP_BACKEND_URL + citizen.banner.url;

  let contentTitle = "";
  if (citizen.contentTitle != null) contentTitle = citizen.contentTitle;

  let content = "";
  if (citizen.content != null) content = citizen.content;

  return (
    <>
      <ImageTitle img={icon} title={title} bg={banner} />
      <SubMenu links={links} titles={titles} icon={icon} />
      <Description title={contentTitle} content={content} />
      <RenderEmpty titles={titles} content={content} title={contentTitle} />
    </>
  );
};

export default Citizen;
