import React, { useState, useEffect } from "react";
import BigMenu from "./BigMenu";
import HomeNews from "./HomeNews";
import HomeEvents from "./HomeEvents";
import UsefulLinks from "./UsefulLinks";
import Slider from "./Slider";
import Welcome from "./Welcome";
import MayorsMsg from "./MayorMsg";

const Home = ({ titles, routes, images }) => {
  useEffect(() => {
    fetchHome();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [home, setHome] = useState({});
  const fetchHome = async () => {
    const fetchHome = await fetch(process.env.REACT_APP_BACKEND_URL + "/home");
    const home = await fetchHome.json();
    setHome(home);
  };

  let wTitle = "Καλώς ήρθατε στον Δήμο Ερέτριας";
  let wContent;
  if (home.welcomeText != null) {
    wContent = home.welcomeText;
  }
  let wVideos;
  if (home.videoLink != null) {
    wVideos = home.videoLink;
  }
  let bg;
  if (home.background != null) {
    bg = process.env.REACT_APP_BACKEND_URL + home.background.url;
  }

  let mTitle = "Χαιρετισμός Δημάρχου";
  let mContent;
  if (home.mayorsMsg != null) {
    mContent = home.mayorsMsg;
  }

  let mImage;
  if (home.mayorsImg != null) {
    mImage = home.mayorsImg;
  }

  return (
    <div>
      <BigMenu titles={titles} routes={routes} images={images} bg={bg} />
      <Welcome title={wTitle} content={wContent} video={wVideos} />
      <MayorsMsg title={mTitle} content={mContent} image={mImage} />
      <HomeNews />
      <Slider />
      <HomeEvents />
      <UsefulLinks />
    </div>
  );
};

export default Home;
