import React, { useState, useEffect } from "react";
import ImageTitle from "../default/ImageTitle";
import icon from "../../assets/img/tourism-icon.png";
import SubMenu from "../default/SubMenu";
import Description from "../default/Description";
import RenderEmpty from "../default/RenderEmpty";

const Tourism = () => {
  useEffect(() => {
    fetchTourism();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [tourism, setTourism] = useState({});
  const fetchTourism = async () => {
    const fetchTourism = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/tourism"
    );
    const tourism = await fetchTourism.json();
    setTourism(tourism);
  };

  let titles = [];
  let links = [];
  if (tourism.categories != null) {
    for (let i in tourism.categories) {
      titles.push(tourism.categories[i].title);
      links.push("/blog/category/" + tourism.categories[i].slug + "/page/1");
    }
  }
  if (tourism.pages != null) {
    for (let i in tourism.pages) {
      titles.push(tourism.pages[i].title);
      links.push("/page/" + tourism.pages[i].slug);
    }
  }

  let title;
  if (
    tourism.title === null ||
    tourism.title === "" ||
    tourism.title === undefined
  )
    title = "Τουρισμός";
  else title = tourism.title;

  let banner;
  if (tourism.banner != null)
    banner = process.env.REACT_APP_BACKEND_URL + tourism.banner.url;

  let contentTitle = "";
  if (tourism.contentTitle != null) contentTitle = tourism.contentTitle;

  let content = "";
  if (tourism.content != null) content = tourism.content;

  return (
    <div>
      <ImageTitle img={icon} title={title} bg={banner} />
      <SubMenu links={links} titles={titles} icon={icon} />
      <Description title={contentTitle} content={content} />
      <RenderEmpty titles={titles} title={contentTitle} content={content} />
    </div>
  );
};

export default Tourism;
