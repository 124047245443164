const ParseContent = (content) => {
  content = content + "";
  content = content.replaceAll(
    "/uploads/",
    process.env.REACT_APP_BACKEND_URL + "/uploads/"
  );
  content = content.replaceAll(
    '<oembed url="https://www.youtube.com/watch?v=',
    '<iframe class="videoSize" width="100%"  src="https://www.youtube.com/embed/'
  );
  content = content.replaceAll(
    '<oembed url="https://youtu.be/',
    '<iframe class="videoSize" width="100%"  src="https://www.youtube.com/embed/'
  );
  content = content.replaceAll(
    "></oembed>",
    'frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  );
  content = content.replaceAll(
    '<oembed url="https://www.google.com/maps/',
    '<iframe width="100%" class="mapSize" src="https://www.google.com/maps/'
  );
  content = content.replaceAll(
    "https://eretria.streamings.gr/live/live/index.m3u8",
    ""
  );
  return content;
};

export default ParseContent;
