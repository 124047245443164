import React, { useEffect, useState } from "react";
import Department from "./Department";
import { Link } from "react-router-dom";

const Management = ({ slug }) => {
  useEffect(() => {
    fetchManagement();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [management, setManagement] = useState({});
  const fetchManagement = async () => {
    const fetchManagement = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/managements?slug=" + slug
    );
    const management = await fetchManagement.json();
    setManagement(management);
  };
  let title;
  let link = "";
  let departments = [];
  let image = "";
  let icon = "";
  if (management[0] != null) {
    title = management[0].title;
    if (management[0].page != null) link = "/page/" + management[0].page.slug;

    if (management[0].image != null)
      image = process.env.REACT_APP_BACKEND_URL + management[0].image.url;

    if (management[0].icon != null)
      icon = process.env.REACT_APP_BACKEND_URL + management[0].icon.url;

    for (let i in management[0].departments) {
      departments.push(
        <Department
          key={i}
          slug={management[0].departments[i].slug}
          icon={icon}
        />
      );
    }
  }
  return (
    <div className="col-lg-4 col-md-12 col-sm-12">
      <Link to={link}>
        <div className="mana-card" style={{ backgroundImage: `url(${icon})` }}>
          <img className="card-img-management" src={image} alt={title} />
          <div className="mana-title">
            <p>{title}</p>
          </div>
        </div>
      </Link>
      <div className="row">{departments}</div>
    </div>
  );
};
export default Management;

