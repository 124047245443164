import React from "react";

const Welcome = (props) => {
  return (
    <div className="container-fluid add-padding">
      <div className="article">
        <h1 className="sub-title-2">{props.title}</h1>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="editor" style={{ textAlign: "initial" }}>
              <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <iframe
              className="rounded"
              style={{ height: "100%", width: "100%" }}
              title="video"
              src={props.video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <video src="https://eretria.streamings.gr/live/live/index.m3u8"></video> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
