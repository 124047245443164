import React from "react";
import SubItem from "../default/SubItem";

const DisplayItems = ({ titles, header, subItems }) => {
  if (titles.length > 0) {
    return (
      <div className="container">
        <div className="article">
          <h1 className="sub-title-2">{header}</h1>
          <div className="row">{subItems}</div>
        </div>
      </div>
    );
  } else return null;
};

const BusinessSubMenu = ({ links, titles, icon, header }) => {
  const subItems = [];
  for (let i = 0; i < titles.length; i++) {
    subItems.push(
      <SubItem key={i} link={links[i]} title={titles[i]} icon={icon} />
    );
  }

  return <DisplayItems titles={titles} header={header} subItems={subItems} />;
};

export default BusinessSubMenu;
