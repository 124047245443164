import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
const SliderItem = ({ img, title, link, style1, style2 }) => {
  return (
    <SplideSlide>
      <div className={style1}>
        <a href={link}>
          <img className={style2} src={img} alt={title} />
        </a>
      </div>
    </SplideSlide>
  );
};

export default SliderItem;
